import Cookies from "js-cookie";
import { SSOErrorStatus } from "misc/types";

/**
 * Information about secondary eTLD domain request
 */
interface SecondaryDomainRequest {
  readonly nonce: string;
  readonly secondaryDomain: string;
}

export interface SessionStatus {
  readonly session_uuid: string;
  readonly client_cert_valid: boolean;
  readonly totp_enabled: boolean;
  readonly display_otp_enabled: boolean;
  readonly otp_enabled: boolean;
  readonly authenticated: boolean;
  readonly auth_prompt: string;
  readonly amr: string[];
  readonly require_2fa: boolean;
  readonly mcs_andon_cord_pulled: boolean;
}

export interface PageMetaTags {
  readonly my_security_key_settings_link: string;
  readonly secured_by_midway_partition_name: string;
  readonly secured_by_midway_wiki_link: string;
  readonly midway_partition: string;
  readonly compliance_validation_failed: string;
  readonly posture_error_message: string;
  readonly device_type: string;
  readonly aea_app_redirect: string;
  readonly posture_verification_failed: string;
}

// TODO: generalize this type so that it can be used by all errors
export interface ErrorInfo {
  readonly status: SSOErrorStatus;
  readonly message: string;
}

function extractSessionStatus() {
  const sessionStatusJson = document.querySelector("meta[id='session-status']")?.getAttribute("content");
  return JSON.parse(sessionStatusJson ?? "{}") as SessionStatus;
}

function extractEnableMcsBrowserFeature() {
  const enableMcsBrowserFeat = document.querySelector("meta[id='enable-mcs-browser-feature']")?.getAttribute("content");
  return enableMcsBrowserFeat === "true";
}

export function extractMetaTagsContent(): PageMetaTags {
  const sessionDetailsJson = document.querySelector("meta[id='ui-params']")?.getAttribute("content");
  return JSON.parse(sessionDetailsJson ?? "{}") as PageMetaTags;
}

export function extractErrorInfo() {
  const errorInfoJson = document.querySelector("meta[id='error-info']")?.getAttribute("content");
  return JSON.parse(errorInfoJson ?? "{}") as ErrorInfo;
}

const sessionStatus = extractSessionStatus();
const initialUserName = Cookies.get("user_name");
// Get the query string from the current URL
const queryString = window.location.search;

// Parse the query string into an object
const queryParams = new URLSearchParams(queryString);

export interface PageInfo {
  readonly sessionUuid: string;
  readonly userName?: string;
  readonly isAuthenticated: boolean;
  readonly isTotpEnabled: boolean;
  readonly isDisplayOtpEnabled: boolean;
  readonly isOtpEnabled: boolean;
  readonly isClientCertValid: boolean;
  readonly authPrompt: string;
  readonly amr: string[];
  readonly require_2fa: boolean;
  readonly queryParams: URLSearchParams;
  // If present this is a request from secondary domain.
  readonly secondaryDomainRequest?: SecondaryDomainRequest;
  readonly enableMcsBrowserFeature: boolean;
}

interface JSParams {
  claims: string[];
  readonly next_url: string;
  readonly error_code: string;
}

/**
 * Extracts secondary domain request information from query parameters.
 */
function extractSecondaryDomainRequest(): SecondaryDomainRequest | undefined {
  const nonce = queryParams.get("secondary_nonce");
  const secondaryDomain = queryParams.get("secondary_domain");
  if (nonce && secondaryDomain) {
    return {
      nonce: nonce,
      secondaryDomain: secondaryDomain,
    };
  }
}

export const pageMetaTags = extractMetaTagsContent();

export const pageInfo: PageInfo = {
  sessionUuid: sessionStatus.session_uuid,
  userName: initialUserName,
  isAuthenticated: sessionStatus.authenticated,
  isTotpEnabled: sessionStatus.totp_enabled,
  isDisplayOtpEnabled: sessionStatus.display_otp_enabled,
  isOtpEnabled: sessionStatus.otp_enabled,
  isClientCertValid: sessionStatus.client_cert_valid,
  authPrompt: sessionStatus.auth_prompt,
  amr: sessionStatus.amr,
  require_2fa: sessionStatus.require_2fa,
  queryParams: queryParams,
  secondaryDomainRequest: extractSecondaryDomainRequest(),
  enableMcsBrowserFeature: extractEnableMcsBrowserFeature(),
};

export const errorInfo = extractErrorInfo();

export function extractJSParams() {
  const jsParams = document.querySelector("meta[id='js-params']")?.getAttribute("content");
  return JSON.parse(jsParams ?? "{}") as JSParams;
}

export const jsParams: JSParams = extractJSParams();
